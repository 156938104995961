import React, {useContext, useEffect} from 'react';
import {useParams, Redirect} from 'react-router-dom';

import moment from 'moment';

import Context from './context';

import useLocalStorage from '../../hooks/useLocalStorage';

import EmailSignupForm from './emailSignUpForm';



export default function OfferPage({setParallaxImage}) {
	const {offers, initialOffersLoaded, selectedState, gaEvent} = useContext(Context);

	const {id} = useParams();
	const offer = offers.find(offer => offer.id === Number(id));

	const [emailSignupCompleted, setEmailSignupCompleted] = useLocalStorage('emailSignupCompleted', 'false');

	useEffect( () => {
		if (offer) gaEvent('Interaction', 'View Offer', `${offer.name} (${offer.id})`, offer.id);

		setParallaxImage(offer ? offer.image_url : false);
		return () => setParallaxImage(false);
	}, [offer] );

	if (initialOffersLoaded && !offer) return <Redirect to="/" />;
	if (!offer) return <></>;

	// Move this to retailer table in DB
	const assetsByRetailerName = offer.retailers.reduce( (assetsByRetailerName, retailer) => {
		const retailerName = retailer.name.toLowerCase();

		assetsByRetailerName[retailerName] = {};

		switch(retailerName) {
			case 'target':
				assetsByRetailerName[retailerName].link = `https://www.target.com/circle/offers/482${ offer.target_social_id ? `?detail=${offer.target_social_id}` : '' }`;
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/target.svg';
				break;
			case 'speedway':
				assetsByRetailerName[retailerName].link = 'https://www.speedway.com/speedy-rewards';
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/speedway.svg';
				break;
			case 'udf':
				assetsByRetailerName[retailerName].link = 'https://www.udfinc.com/u-drive/';
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/udf.svg';
				break;
			case 'getgo':
				assetsByRetailerName[retailerName].link = 'https://getgo.bybeapp.com/offers';
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/getGo.svg';
				break;
			case 'top ten liquors':
				assetsByRetailerName[retailerName].link = `https://app.bybeapp.com/offer-detail/${selectedState.name.toLowerCase()}/${offer.id}`;
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/topTenLiquors.svg';
				break;
			case 'oncue':
				assetsByRetailerName[retailerName].link = `https://app.bybeapp.com/offer-detail/${selectedState.name.toLowerCase()}/${offer.id}`;
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/onCue.png';
				break;
			case 'hns':
				assetsByRetailerName[retailerName].link = `https://app.bybeapp.com/offer-detail/${selectedState.name.toLowerCase()}/${offer.id}`;
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/hns.png';
				break;
			case 'jacksons':
				assetsByRetailerName[retailerName].link = 'https://www.jacksons.com/letsgorewards';
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/jacksons.jpg';
				break;
			default:
				assetsByRetailerName[retailerName].link = `https://app.bybeapp.com/offer-detail/${selectedState.name.toLowerCase()}/${offer.id}`;
				assetsByRetailerName[retailerName].logo = '/assets/images/offers/bybe.svg';
				break;
		}

		return assetsByRetailerName;
	}, {} );

	const featuredRetailer = offer.retailers.find(retailer => retailer.name.toLowerCase() === 'target');
	const bybeRetailer = offer.retailers.find(retailer => retailer.name.toLowerCase() === 'bybe');

	const otherRetailers = offer.retailers.filter(retailer => !['bybe', 'target', 'brand site retailer'].includes(retailer.name.toLowerCase()));

    return <div className="container w-full lgplus:w-3/5 mx-auto py-2 md:py-4 text-center text-dark">
		<div className="mx-auto mb-8">
			<h1 className="mb-1 text-3xl md:text-4xl uppercase font-bold">{offer.name}</h1>
			<p className="mb-1 text-sm">{offer.details}</p>
			<div className="text-sm uppercase">{offer.per_consumer_limit} Per Household. Ends {moment.utc(offer.end_date).format('MM.DD.YYYY')}</div>
		</div>

		<h2 className="mb-4 text-lg md:text-xl lgplus:text-2xl uppercase font-bold">Where to save</h2>

		{ featuredRetailer || bybeRetailer || otherRetailers.length || offer.brand ? <div className="flex flex-wrap justify-center gap-4 mx-auto mb-8 text-base">
			{ featuredRetailer ? <a href={assetsByRetailerName[featuredRetailer.name.toLowerCase()].link}
				className="flex items-center justify-center h-24 w-24 rounded-full bg-white shadow-2xl"
				onClick={() => gaEvent('Interaction', 'Partner Site Visit', `retailer:${featuredRetailer.name}, state:${selectedState.name}, offer:${offer.name}, offerId:${offer.id}`)} target="_blank"
				style={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}}>
				<img className="w-36" src={featuredRetailer.secondary_logo_image_url} />
			</a> : null }

			{ otherRetailers.map( retailer => {
				return <a key={`otherRetailer_${retailer.name}`}
				className="flex items-center justify-center h-24 w-24 rounded-full bg-white"
					href={assetsByRetailerName[retailer.name.toLowerCase()].link}
					onClick={() => gaEvent('Interaction', 'Partner Site Visit', `retailer:${retailer.name}, state:${selectedState.name}, offer:${offer.name}, offerId:${offer.id}`)} target="_blank"
					style={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}}>
					<img className="w-14" src={retailer.secondary_logo_image_url} />
				</a>;
			} ) }

			{offer.brand ? <a key={`brand_${offer.brand.name}`}
				className="flex items-center justify-center h-24 w-24 rounded-full bg-white"
				href={`https://${offer.brand.redemption_site_subdomain}.byberebates.com/offer/${offer.id}`}
				onClick={() => gaEvent('Interaction', 'Partner Site Visit', `retailer:${offer.brand.name}, state:${selectedState.name}, offer:${offer.name}, offerId: ${offer.id}`)} target="_blank"
				style={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}}>
				<img className="w-14" src={offer.brand.logo_image_url} />
			</a> : null }

			{bybeRetailer ? <a href={assetsByRetailerName[bybeRetailer.name.toLowerCase()].link}
				className="flex items-center justify-center h-24 w-24 rounded-full font-semibold bg-white"
				onClick={() => gaEvent('Interaction', 'Partner Site Visit', `retailer:${bybeRetailer.name}, state:${selectedState.name}, offer:${offer.name}, offerId:${offer.id}`)} target="_blank"
				style={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}}>
				<img className="w-14" src={bybeRetailer.secondary_logo_image_url} />
			</a> : null }
		</div> : <></> }

		{ JSON.parse(emailSignupCompleted) ? <div className="w-full md:w-1/2 mx-auto mb-8">
			<p className="text-sm">Look out for awesome new offers in your inbox!</p>
		</div> : <EmailSignupForm header="Find out when new offers are available"
			extraClasses="mb-8"
			setEmailSignupCompleted={setEmailSignupCompleted} /> }

		<p className="mx-auto text-2xs text-gray-500">
			Offer valid from {moment.utc(offer.start_date).format('MM-DD-YYYY')} – {moment.utc(offer.end_date).format('MM-DD-YYYY')}. <br />
			{offer.conditions}<br/>
			Offer ID: {offer.id}.
		</p>
	</div>;
}